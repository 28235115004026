import React from "react";
import "../../style/footer.css";
import { MdOutlineMail } from "react-icons/md";
import {
  AiFillFacebook,
  AiFillTwitterSquare,
  AiFillInstagram,
  AiFillLinkedin,
} from "react-icons/ai";
import { FiPhone } from "react-icons/fi";
import { GoLocation } from "react-icons/go";
import { TbPointFilled } from "react-icons/tb";
import TriTonLogo1 from "../../assets/images/logo mark.png";
import { Link, useNavigate } from "react-router-dom";

function Footer() {
  const Navigate = useNavigate();
  const ContactClick = () => {
    Navigate("/contact-us");
  };
  return (
    <>
      <div className="footer">
        <div className="footer-top">
          <div className="fooder-first-line">
            <div className="footer-logo">
              <img width={'100px'} src={TriTonLogo1} alt="tritonlogo" />
            </div>
            <div className="footer-menu">
              <h4 className="footer-title">Menu</h4>
              <ul className="footer-link">
                <li className="footer-sub-link">
                  <Link className="footer-router-link" to={"/home"}>
                    Home
                  </Link>
                </li>
                <li className="footer-sub-link">
                  {" "}
                  <Link className="footer-router-link" to={"/services"}>
                    Services
                  </Link>
                </li>
                <li className="footer-sub-link">
                  {" "}
                  <Link className="footer-router-link" to={"/products"}>
                    Products
                  </Link>
                </li>
                <li className="footer-sub-link">
                  {" "}
                  <Link className="footer-router-link" to={"/careers"}>
                    Careers
                  </Link>
                </li>
                <li className="footer-sub-link">
                  {" "}
                  <Link className="footer-router-link" to={"/about-us"}>
                    About Us
                  </Link>
                </li>
                <li className="footer-sub-link">
                  {" "}
                  <Link className="footer-router-link" to={"/contact-us"}>
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-services">
              <h4 className="footer-title">Services</h4>

              <ul className="footer-link">
                <li className="footer-sub-link">
                  {" "}
                  <Link className="footer-router-link" to={"/services"}>
                    IoT Solutions
                  </Link></li>
                <li className="footer-sub-link">
                  {" "}
                  <Link className="footer-router-link" to={"/services"}>
                    Cloud solutions
                  </Link></li>
                <li className="footer-sub-link">
                  {" "}
                  <Link className="footer-router-link" to={"/services"}>
                    Mobile App Development
                  </Link></li>
                <li className="footer-sub-link">
                  {" "}
                  <Link className="footer-router-link" to={"/services"}>
                    Custom Software Development
                  </Link></li>
                <li className="footer-sub-link">
                  {" "}
                  <Link className="footer-router-link" to={"/services"}>
                    Web Development
                  </Link></li>
                <li className="footer-sub-link">
                  {" "}
                  <Link className="footer-router-link" to={"/services"}>
                    Big Data Analytics
                  </Link></li>
              </ul>
            </div>
          </div>
          <div className="fooder-second-line">
            <div className="footer-product">
              <h4 className="footer-title">Products</h4>
              <ul className="footer-link">
                <li className="footer-sub-link">
                  <Link className="footer-router-link" to="/product/dtds">
                    DTDS
                  </Link>
                </li>
                <li className="footer-sub-link">
                  <Link className="footer-router-link" to="/product/swms">
                    SWMS
                  </Link>
                </li>
                <li className="footer-sub-link">
                  <Link
                    className="footer-router-link"
                    to="/product/vital-sense"
                  >
                    Vital Sense
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-follow-us-on">
              <h4 className="footer-title">Follow Us On</h4>
              <ul className="footer-link">
                <li
                  className="footer-icons footer-sub-link"
                  style={{ fontSize: "30px" }}
                >
                  <a href="https://www.facebook.com/profile.php?id=100076158587804" target="_blank" rel="noopener noreferrer" ><AiFillFacebook className="footer-links" /></a>
                  &nbsp;
                  <a href="" target="_blank" rel="noopener noreferrer"><AiFillTwitterSquare className="footer-links" /></a>
                  &nbsp;
                  <a href="https://instagram.com/triton_tech_labs?igshid=YmMyMTA2M2Y=" target="_blank" rel="noopener noreferrer" ><AiFillInstagram className="footer-links" /></a>
                  &nbsp;
                  <a href="https://www.linkedin.com/company/triton-tech-labs/" target="_blank" rel="noopener noreferrer" ><AiFillLinkedin className="footer-links" /></a>
                </li>
              </ul>
            </div>
            <div className="footer-contact">
              <h4 className="footer-title">Contact</h4>
              <ul className="footer-link">
                <li className="footer-sub-link" >
                  <MdOutlineMail />
                  &nbsp;info@tritonlabs.in
                </li>
                <li className="footer-sub-link">
                  <FiPhone /> &nbsp;+91 9944393255
                </li>
                <li className="footer-sub-content"><GoLocation />  Branches</li>
                <li
                  className="footer-sub-content"
                >
                  <TbPointFilled />
                  &nbsp;219, First Floor, Main Road,
                  Kadayanallur, Tenkasi, Tamil Nadu, India-627751
                </li>
                <li
                  className="footer-sub-content"
                >
                  <TbPointFilled />
                  &nbsp;191, 3rd Floor, Hamid Building,
                  Whites Road, Anna Salai, Chennai, Tamil Nadu, India-600006
                </li>
              </ul>
              <button className="footer-btn" onClick={() => ContactClick()}>
                Enquiry
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-end">
        <p>© 2023 Triton Tech Labs. All Rights Reserved.</p>
      </div>
    </>
  );
}

export default Footer;
