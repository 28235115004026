import "./App.css";
import { Routing } from "./routes/Routing";
import React ,{useEffect} from "react";
import ReactGA from 'react-ga';


const App = () => {
  useEffect(() => {
    ReactGA.initialize('G-015SJWSMH7');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <div className="app">
      <Routing />
    </div>
  );
};
export default App;
