import React, { useEffect, useRef } from "react";

function DtdsTermsAndCondition() {
  const dtdsTermsAndConditionRef = useRef(null);
  useEffect(() => {
    dtdsTermsAndConditionRef.current.scrollIntoView({ behavior: "smooth" }, (0, 400));
  }, []);
  return (
    <div className="terms-condition-main-page">
      <div className="sub-terms-condition-page">
        <div className="contant-terms-condition-page">
          <div className="terms-condition-topadj" ref={dtdsTermsAndConditionRef}></div>
          <div className="terms-condition-heading-text">
            <p className="terms-condition-move-text-center">
              Welcome to the Smart DIGITAL TOKEN DISPLAY SYSTEM. Please read these terms and conditions
              carefully before using our product.
            </p>
          </div>
          <div className="all-terms-condition-para-main">
            <ol>
              <div className="terms-condition-para-text">
                <li>
                  Acceptance of terms by accessing or using the DIGITAL TOKEN DISPLAY SYSTEM, you
                  agree to be bound by these terms and conditions.
                </li>
              </div>
              <div className="terms-condition-para-text">
                <li>
                  Use of the product you may use the DIGITAL TOKEN DISPLAY SYSTEM solely for your
                  personal or business use. You may not reproduce, distribute
                  or modify any part of the product without our prior written
                  consent.
                </li>
              </div>
              <div className="terms-condition-para-text">
                <li>
                  Payment and fees you agree to pay all fees and charges
                  associated with the use of the DIGITAL TOKEN DISPLAY SYSTEM. We reserve the
                  right to modify our fees and charges at any time with notice
                  to you.
                </li>
              </div>
              <div className="terms-condition-para-text">
                <li>
                  Intellectual property in the DIGITAL TOKEN DISPLAY SYSTEM and all content and
                  materials included in the product is protected by copyright
                  and other intellectual property laws. You may not use any of
                  our intellectual property without our prior written consent.
                </li>
              </div>
              <div className="terms-condition-para-text">
                <li>
                  Privacy we are committed to protecting your privacy. Please
                  refer to our privacy policy for more information.
                </li>
              </div>
              <div className="terms-condition-para-text">
                <li>
                  Disclaimer of warranties the DIGITAL TOKEN DISPLAY SYSTEM is provided on an "as
                  is" and "as available" basis. We make no warranties or
                  representations of any kind, express or implied, regarding the
                  use or performance of the product.
                </li>
              </div>
              <div className="terms-condition-para-text">
                <li>
                  Limitation of liability in no event shall we be liable for any
                  direct, indirect, incidental, special or consequential
                  damages arising out of or in connection with the use of the
                  DIGITAL TOKEN DISPLAY SYSTEM.
                </li>
              </div>
              <div className="terms-condition-para-text">
                <li>
                  Governing law these terms and conditions shall be governed by
                  and construed in accordance with the laws of the jurisdiction
                  in which we operate.
                </li>
              </div>
              <div className="terms-condition-para-text">
                <li>
                  Modifications to the terms and conditions we reserve the right
                  to modify these terms and conditions at any time without prior
                  notice. By continuing to use the DIGITAL TOKEN DISPLAY SYSTEM after we post any
                  such changes, you accept the terms and conditions as modified.
                </li>
              </div>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DtdsTermsAndCondition;
