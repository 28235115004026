import React, { useEffect, useRef } from "react";
import "../../style/ourProduct.css";
import Dtds from "../../assets/images/dtds.png";
import Swms from "../../assets/images/swms.png";
import Mask from "../../assets/images/mask group.png";
import Vector from "../../assets/images/vector-product.png";
import vital from "../../assets/images/vital.png";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/nav-bar/Footer";
import ReactGA from 'react-ga';

function OurProduct() {
  const Navigate = useNavigate();
  const swmsClick = () => {
    ReactGA.event({
      category: 'SWMS',
      action: 'Navigate to SWMS page'
    });
    Navigate("/product/swms");
  };
  const dtdsClick = () => {
    ReactGA.event({
      category: 'DTDS',
      action: 'Navigate to DTDS page'
    });
    Navigate("/product/dtds");
  };
  const vitalClick = () => {
    ReactGA.event({
      category: 'Vital-Sense',
      action: 'Navigate to Vital-Sense page'
    });
    Navigate("/product/vital-sense");
  };
  const productRef = useRef(null);
  useEffect(() => {
    productRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  return (
    <>
      <div className="our-product" ref={productRef}>
        <div className="product-list  mask-image">
          <div className="mask-page-product">
            <div className="mask-content-product">
              <h1 className="mask-text-product">Innovative Solutions  </h1>
              <h1 className="mask-text-product"> To Transform Your Industry </h1>
              <p className="mask-para-product">
                Discover our innovative products designed to simplify your daily
                life and transform your business. From cutting-edge IoT
                solutions to intuitive mobile apps, we're here to help you stay
                ahead of the curve and achieve your goals.
              </p>
            </div>
            <img
              src={Mask}
              width={"50%"}
              alt="swms"
              className="mask-img-product"
            />
          </div>
          <div className="vector-product">
            <img src={Vector} className="vector-img" alt="vector" />
            <p className="vector-text-product">Our Products</p>
          </div>
          <div className="dtds-page-product">
            <div className="dtds-content-product">
              <h1 className="dtds-text-product">Digital Token </h1>
              <h1 className="dtds-text-product">
                Display System <span className="dtds-blue-product">(DTDS)</span>
              </h1>
              <p className="dtds-para-product">
                The main users of the DTDS token booking app are patients and
                doctors. Doctors can easily maintain their patients with their
                devices, patients may book a token with a single touch at home,
                and we can connect with a smart TV to display the token number in
                hospitals.
              </p>
              <div className="dtds-view-btn-product">
                <button onClick={dtdsClick} className="dtds-btn-product button">
                  View More
                </button>
              </div>
            </div>
            <img
              src={Dtds}
              alt="dtds"
              className="dtds-img-product"
            />
          </div>
          <div className="swms-page-product">
            <img
              src={Swms}
              alt="swms"
              className="dtds-img-product-swms"
            />
            <div className="swms-content-product">
              <h1 className="swms-text-product">Smart Water Management</h1>
              <h1 className="swms-text-product">
                {" "}
                System <span className="swms-blue-product">(SWMS)</span>
              </h1>
              <p className="swms-para-product">
                SWMS is an innovative water management system designed to help
                businesses and organizations save money, reduce waste, and
                improve sustainability. Using IoT sensors and advanced data
                analytics, SWMS provides real-time monitoring of water usage,
                identifies leaks and inefficiencies, and generates customized
                reports and alerts. With SWMS, you can take control of your
                water usage and make data-driven decisions that benefit both
                your bottom line and the environment.
              </p>
              <div className="swms-view-btn-product">
                <button onClick={swmsClick} className="dtds-btn-product">
                  View More
                </button>
              </div>
            </div>
          </div>
          <div className="vital-page-product">
            <div className="vital-content-product">
              <h1 className="vital-text-product">Vital Sense </h1>
              <p className="vital-para-product">
                Vital Sense is our innovative health monitoring device that helps
                healthcare professionals track patients' body temperature,
                height, and weight with ease. Designed for hospitals and
                clinics, Vital Sense is the ideal solution for quickly and
                accurately recording vital signs. Our device is user-friendly
                and seamlessly integrates with mobile devices, allowing
                healthcare providers to access patient data from anywhere, at
                any time. With Vital Sense, we aim to empower healthcare
                professionals with the tools they need to provide the highest
                quality care for their patients.
              </p>
              <div className="vital-view-btn-product">
                <button onClick={vitalClick} className="vital-btn-product">
                  View More
                </button>
              </div>
            </div>
            <img src={vital} width={"50%"} alt="vital" className="vital-img-product" />
          </div>
          <div style={{ marginTop: "20px" }}> <Footer /> </div>
        </div>
      </div>
    </>
  );
}

export default OurProduct;
