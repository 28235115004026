import React, { useEffect, useRef } from "react";
import "../../style/dashboard.css";
import GroupPage from "../../assets/images/group-page.svg";
import VectorFixedImg from "../../assets/images/vector-img.svg";
import MinBox from "../../assets/images/min-box.svg";
import MaxBox from "../../assets/images/max-box.svg";
import SystemBoxLeft from "../../assets/images/system-box.svg";
import Footer from "../../components/nav-bar/Footer";
function Dashboard() {
  const dashboardRef = useRef(null);
  useEffect(() => {
    dashboardRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  return (
    <div className="dashboard-page" ref={dashboardRef}>
      <div className="home-container-page">
        <div className="home-first-pages">
          <div className="left-side-home">
            <div className="left-side-home-head">
              <div className="heading-text-page">
                <h1>Transforming Your Digital Dreams Into Reality</h1>
              </div>
              <div className="home-para">
                At Triton Tech Labs, we specialize in transforming your digital
                dreams into reality. With our expert digital development
                services, we can help you create robust solutions that meet your
                unique needs.
              </div>
              <div className="mobile-content">
                At Triton Tech Labs, we specialize in transforming your digital
                dreams into reality. With our expert digital development
                services, we can help you create robust solutions that meet your
                unique needs.
              </div>
              <div className="home-page-btn">
                <div className="button-enquiry">
                  <a href="#expertise">
                    <button className="Our-Expertise-click button">
                      Our Expertise
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="group-page-img">
            <img src={GroupPage} className="img-groups" alt="GroupPage" />
          </div>
        </div>
        <div className="home-heading-para-vector">
          <div className="home-heading-vector">
            <h1>
              discover how we work <br />
              to deliver outstanding results
            </h1>
          </div>
          <div className="home-para-vector">
            At Triton Tech Labs, we follow an agile development process to
            ensure that we deliver quality solutions that meet your unique
            needs. Our process is designed to be flexible and adaptable,
            allowing us to respond quickly to changing requirements and deliver
            results that exceed your expectations. Triton provides a project
            life cycle of consulting; software analysis, design, architecture, development, and support.
          </div>
        </div>
        <div className="lanch-vector-line-head">
          <div className="lanch-vector-line">
            <img
              src={VectorFixedImg}
              className="lanch-vector-content"
              alt="vectorAll"
            />
          </div>
        </div>
        <div className="home-expertise-page">
          <div className="succes-full-img">
            <h2>We Help Our Clients For Their Successful Business</h2>
          </div>
        </div>
        <div id="expertise" className="home-system-page">
          <div className="home-system-content">
            <div className="home-system-content-head">
              <div className="our-expertise">
                <h1>Our Expertise</h1>
              </div>
              <div className="expertise-para">
                <p>
                  At Triton Tech Labs, we understand that our clients have
                  unique business goals and challenges. We take pride in
                  modernizing your existing monolithic application as well as
                  delivering new solutions from the ground up to meet the growing
                  demands of our customers. Our team of experts has extensive
                  experience in a variety of industries, including:
                </p>
              </div>
              <div className="min-max-img">
                <img className="min-box-img" src={MinBox} alt="VectorLine" />
                <img className="max-box-img" src={MaxBox} alt="VectorLine" />
              </div>
              <div className="expertise-para-footer">
                <p>
                  We use the latest tools and technologies to deliver innovative
                  solutions that help our clients stay ahead of the curve and
                  achieve their business objectives. Our goal is to help you
                  realize your vision and achieve long-term success
                </p>
              </div>
            </div>
          </div>
          <div className="system-home-page-img">
            <div className="system-home-img">
              <img
                className="systm-img-left"
                src={SystemBoxLeft}
                alt="VectorLine"
              />
            </div>
          </div>
          {/* <div className="home-email-subscribe-page">
          <div className="home-sub-page">
            <div className="news-subscribe">
              <h1>
                Subscribe To <br /> Our News Letter
              </h1>
            </div>
            <div className="input-box-input">
              <div className="email-home-box">
                <input
                  type="email"
                  placeholder="Enter Your E-mail"
                  className="input-field"
                ></input>
              </div>
              <div className="button-click">
                <button className="button-click-home">Subscribe</button>
              </div>
            </div>
          </div>
        </div> */}
        </div>
        <div className="footer-class">
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
