import React, { useEffect, useRef } from "react";
import "../../style/aboutus.css";
import Wholesystem from "../../assets/images/wholesystem.svg";
import Who from "../../assets/images/who.svg";
import What from "../../assets/images/what.svg";
import Choose from "../../assets/images/choose.svg";
import Light from "../../assets/images/light.svg";
import Footer from "../../components/nav-bar/Footer";

function AboutUs() {
  const aboutRef = useRef(null);
  useEffect(() => {
    aboutRef.current.scrollIntoView({ behavior: "smooth", block: 'start' });
  }, []);
  return (
    <div className="about-main" ref={aboutRef} >
      <div className="about-innvo">
        <img src={Wholesystem} alt="MAC" className="whole-system" />
      </div>
      <div className="about-about">
        <h1>About Us</h1>
      </div>
      <div className="about-about-us">
        Triton Tech Labs is a digital technology expert that provides customized
        digital solutions to businesses looking to transform their operations
        into modern and efficient platforms. We use the right-fit technology to
        streamline existing business platforms, automate everyday activities and
        provide cloud solutions that are secure and scalable. Our team of
        professionals is dedicated to providing innovative solutions that meet
        the unique needs of each business, ensuring that they remain relevant
        and competitive in the dynamic market.
      </div>
      <div className="who">
        <img src={Who} alt="WHO" />
      </div>
      <div className="about-who">
        <h1>Who we are?</h1>
      </div>
      <p className="about-who-content">
        Triton has extensive experience in Software Development with unparalleled
        expertise, versatile domain knowledge and passionate use of technology,
        we deeply analyze the project and we pick and choose the platforms and
        tools for development, for the best execution of the trust bestows upon
        us by our clients.
      </p>
      <div className="what-we-do">
        <div className="what">
          <img src={What} alt="WHAT" />
        </div>
        <div className="about-what"><h1>What we do?</h1></div>
        <p className="about-what-content">
          Our IT firm provides the following software Solutions like Analysis &
          Consultation, Software Development, Cloud-Based Solutions, Mobile & Web
          App Development, IoT and AI. We provide a full project lifecycle of
          consulting; software analysis, design, architecture, development,
          deployment and support.
        </p>
      </div>
      <div className="choose">
        <img src={Choose} alt="Choose" />
      </div>
      <div className="about-choose">
        <h1>Why you choose us?</h1>
      </div>
      <p className="about-choose-content">
        We, at Triton, are committed to producing work that exceeds our
        client's expectations, work that we can feel proud of. We can develop
        your applications by integrating existing technology and products. Or we
        can start with a blank sheet of paper. As your trusted partner, we
        deliver the right program for your needs with Client-centric Approach,
        Effective Collaboration, and Quality Delivered on Time.
      </p>
      <div className="light">
        <img src={Light} alt="NEW PROJECT" className="light-map" />
      </div>
      <div style={{ marginTop: "30px" }}>
        <Footer />
      </div>
    </div>
  );
}

export default AboutUs;
