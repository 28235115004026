export const Menus = [
  {
    title: "Services",
    path: "services",
    cName: "nav-text",
  },
  {
    title: "Products",
    path: "products",
    cName: "nav-text",
  },
  {
    title: "Careers",
    path: "careers",
    cName: "nav-text",
  },

  {
    title: "About Us",
    path: "about-us",
    cName: "nav-text",
  },
  {
    title: "Contact Us",
    path: "contact-us",
    cName: "nav-text",
  },
];
export const DtdsMenusItem = [
  {
    title: "DTDS",
    path: "",
    cName: "nav-text",
  },
  {
    title: "Terms & Condition",
    path: "terms",
    cName: "nav-text",
  },
  {
    title: "Privacy & Policy",
    path: "privacy",
    cName: "nav-text",
  },
];
export const SwmsMenuItem = [
  {
    title: "SWMS",
    path: "",
    cName: "nav-text",
  },
  {
    title: "Terms & Condition",
    path: "terms",
    cName: "nav-text",
  },
  {
    title: "Privacy & Policy",
    path: "privacy",
    cName: "nav-text",
  },
];
export const VitalMenuItem = [
  {
    title: "Vital Sense",
    path: "",
    cName: "nav-text",
  },
  {
    title: "Terms & Condition",
    path: "terms",
    cName: "nav-text",
  },
  {
    title: "Privacy & Policy",
    path: "privacy",
    cName: "nav-text",
  },
];
