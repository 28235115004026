import React, { useEffect, useRef } from "react";
import "../../style/vitalpage.css";
import Vitalsensorsimg from "../../assets/images/vital-sensors-img.png";
import Vitalwightmeterimg from "../../assets/images/vital-wight-meter-img.png";
import Vitalheightmachineimg from "../../assets/images/vital-height-machine-img.png";
import Vitalbodytempertureimg from "../../assets/images/vital-body-temperture-machine-img.png";
import Vitaldectorcheckupimg from "../../assets/images/vital-dector-checkup-img.png";
import VitaliPhonexflyingmockupleft from "../../assets/images/vital-iPhone-x-flying-mockup-left.png";
import VitalPlayStoreimg from "../../assets/images/vital-playStore.png";
import VitalAppStoreimg from "../../assets/images/vital-appStore.png";
import VitalScanBarimg from "../../assets/images/vital-scan-bar-code.png";
import VitalPagePhoneFirstImg from "../../assets/images/vital-google-pixel-7-pro.png";
import Footer from "../../components/nav-bar/Footer";
import { useNavigate } from "react-router-dom";
function VitalPage() {
  const Navigate = useNavigate();
  const ContactClick = () => {
    Navigate("/contact-us");
  };
  const vitalRef = useRef(null);
  useEffect(() => {
    vitalRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  return (
    <div className="vital-main-page" ref={vitalRef}>
      <div className="vital-container-page">
        <div className="vital-first-pages">
          <div className="vital-page-first-contant">
            <div className="vital-first-heading-contant">
              <h1>Vital Sense</h1>
            </div>
            <div className="vital-first-page-phone-view">
              <img
                className="vital-first-page-phone-img-view"
                src={VitalPagePhoneFirstImg}
                alt="VitalPagePhoneFirstImg"
              />
            </div>
            <div className="vital-first-para-contant">
              Our IoT product enables patients to stand on the device and have
              their height, weight and body temperature analyzed through a
              sensor installed in the machine. The data is then transmitted to
              the Vital Sense application, where the values are displayed. This
              hospital-related app is designed to help doctors analyze a
              patient's condition by providing accurate measurements.
            </div>
          </div>
          <div className="vital-first-page-phone">
            <img
              className="vital-first-page-phone-img"
              src={VitalPagePhoneFirstImg}
              alt="VitalPagePhoneFirstImg"
            />
          </div>
        </div>
        <div className="vital-iot-sensors-img-content">
          <div className="vital-sensors-img-size">
            <img
              className="vital-sensors-img"
              src={Vitalsensorsimg}
              alt="vitalColor"
            />
          </div>
          <div className="vital-sensors-contents">
            The Vital Sense application utilizes IoT sensors to measure and
            analyze patients' body measurements such as height, weight and body
            temperature in hospitals. Once the measurements are taken, the data
            is processed and the results are transmitted to the Vital Sense
            application for analysis.
          </div>
        </div>
        <div className="vital-machine-img-content">
          <div className="vital-machine-firat-img-content">
            <div className="vital-machine-img-weight-size">
              <img
                className="vital-machine-first-img"
                src={Vitalwightmeterimg}
                alt="vitalColor"
              />
            </div>
            <div className="vital-machine-first-content">
              Patient weight was measured using a digital weighing machine and
              the recorded results were automatically sent to the mobile
              application for further analysis.
            </div>
          </div>
          <div className="vital-machine-second-img-content">
            <div className="vital-machine-img-height-size">
              <img
                className="vital-machine-second-img"
                src={Vitalheightmachineimg}
                alt="vitalColor"
              />
            </div>
            <div className="vital-machine-second-content">
              Patient height was measured using a sensor at the top of the
              machine and the recorded results were automatically sent to the
              mobile application for further analysis.
            </div>
          </div>
          <div className="vital-machine-third-img-content">
            <div className="vital-machine-img-temperture-size">
              <img
                className="vital-machine-third-img"
                src={Vitalbodytempertureimg}
                alt="vitalColor"
              />
            </div>
            <div className="vital-machine-third-content">
              A sensor to measure body temperature was located in the center of
              the apparatus. As the patient entered the apparatus, they placed
              their hand close to the sensor to measure their body temperature.
            </div>
          </div>
        </div>
        <div className="vital-page-container">
          <div className="vital-dector-img">
            <img
              className="vital-dector-checkup-img"
              src={Vitaldectorcheckupimg}
              alt="Vitaldectorcheckupimg"
            />
          </div>
        </div>
        <div className="vital-link-and-phone-img">
          <div className="vital-phone-content-link">
            <div className="vital-para-phone">
              <p>Download our new app</p>
              <p style={{ color: "#016CFF" }}>Available on google play</p>
            </div>
            <div className="vital-click-Stores">
              <div className="vital-url-link-img">
                <div>
                  <img
                    className="vital-trtion-link-img-app"
                    src={VitalPlayStoreimg}
                    alt="VitalPlayStoreimg"
                  />
                </div>
                <div>
                  <img
                    className="vital-trtion-link-img-app"
                    src={VitalAppStoreimg}
                    alt="VitalAppStoreimg"
                  />
                </div>
              </div>
              <div className="vital-url-link-img">
                <img
                  className="vital-trtion-link-img"
                  src={VitalScanBarimg}
                  alt="VitalScanBarimg"
                />
              </div>
            </div>
          </div>
          <div className="vital-iphone-img">
            <img
              className="vital-dector-checkup-iphone-img"
              src={VitaliPhonexflyingmockupleft}
              alt="VitaliPhonexflyingmockupleft"
            />
          </div>
        </div>
        <div className="Vital-content-liner-color-img">
          <div className="Vital-content-liner-color">
            <p className="vital-consult">
              Learn more about optimizing your business process or discuss your
              project by scheduling a free consultation
            </p>
          </div>
          <div className="vital-page-btn-liner">
            <button
              onClick={() => ContactClick()}
              className="vital-lets-talk"
            >
              Let’s talk
            </button>
          </div>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default VitalPage;
