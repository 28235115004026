import React, { useState } from "react";
import DtdsHeadNav from "../../components/nav-bar/DtdsHeadNav";
import { DtdsSuperSubRouting, SuperSubRouting } from "../../routes/Routing";
import { useLocation } from "react-router-dom";
import SwmsHeadNav from "../../components/nav-bar/SwmsHeadNav";
import VitalHeadNav from "../../components/nav-bar/VitalHeadNav";

function DtdsMain() {
  const location = useLocation()
  const path = location.pathname.split('/')
  const [ navTitle, setNavTitle ] = useState(path[path.length - 1])
  console.log("path",path);
  return (
    <div className="main">
      <div className="sub-main">
        <div className="head-component">
          <DtdsHeadNav/>
        </div>
        <div className="body-component">
          <DtdsSuperSubRouting />
        </div>
      </div>
    </div>
  );
}

export default DtdsMain;
