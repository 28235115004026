import React from "react";
import SwmsHeadNav from "../../components/nav-bar/SwmsHeadNav";
import { SwmsSuperSubRouting } from "../../routes/Routing";
function SwmsMain() {
  return (
    <div className="main">
      <div className="sub-main">
        <div className="head-component">
          <SwmsHeadNav />
        </div>
        <div className="body-component">
          <SwmsSuperSubRouting />
        </div>
      </div>
    </div>
  );
}

export default SwmsMain;