import { Route, Navigate, Routes ,useLocation } from "react-router-dom";
import AboutUs from "../pages/About Us/AboutUs";
import Careers from "../pages/Careers/Careers";
import ContactUs from "../pages/Contact Us/ContactUs";
import Dashboard from "../pages/Dashboard/dashboard";
import Main from "../pages/main";
import OurProduct from "../pages/Our Product/OurProduct";
import OurService from "../pages/Our Service/OurService";
import React from "react";
import DtdsMain from "../pages/DtdsMain/DtdsMain";
import DtdsPage from "../pages/DTDS/DtdsPage";
import VitalPage from "../pages/VITAL/VitalPage";
import SwmsPage from "../pages/SWMS/SwmsPage";
import SwmsPrivacyAndPolicy from "../pages/SwmsTerms&Condition&Privacy&Policy/SwmsPrivacyAndPolicy";
import SwmsTermsAndCondition from "../pages/SwmsTerms&Condition&Privacy&Policy/SwmsTermsAndCondition";
import DtdsPrivacyAndPolicy from "../pages/DtdsTerms&Condition&Privacy&Policy/DtdsPrivacyAndPolicy";
import DtdsTermsAndCondition from "../pages/DtdsTerms&Condition&Privacy&Policy/DtdsTermsAndCondition";
import VitalPrivacyAndPolicy from "../pages/VitalTerms&Condition&Privacy&Policy/VitalPrivacyAndPolicy";
import VitalTermsAndCondition from "../pages/VitalTerms&Condition&Privacy&Policy/VitalTermsAndCondition";
import SwmsMain from "../pages/SwmsMain/SwmsMain";
import VitalMain from "../pages/VitalMain/VitalMain";

export const Routing = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Navigate replace to="/home" />} />
      <Route path="/*" element={<Main />} />
      <Route path="/product/dtds/*" element={<DtdsMain />} />
      <Route path="/product/swms/*" element={<SwmsMain />} />
      <Route path="/product/vital-sense/*" element={<VitalMain />} />
    </Routes>
  );
};
export const SubRouting = () => {
  const location = useLocation();
  return (
    <Routes location={location}>
      <Route exact path="/home" element={<Dashboard />} />
      <Route exact path="/services" element={<OurService />} />
      <Route exact path="/products" element={<OurProduct />} />
      <Route exact path="/careers" element={<Careers />} />
      <Route exact path="/about-us" element={<AboutUs />} />
      <Route exact path="/contact-us" element={<ContactUs />} />
    </Routes>
  );
};

export const DtdsSuperSubRouting = () => {
  return (
    <Routes>
      <Route exact path="/" element={<DtdsPage />} />
      <Route
        exact
        path="/privacy"
        element={<DtdsPrivacyAndPolicy />}
      />
      <Route
        exact
        path="/terms"
        element={<DtdsTermsAndCondition />}
      />
    </Routes>
  );
};

export const SwmsSuperSubRouting = () => {
  return (
    <Routes>
      <Route exact path="/" element={<SwmsPage />} />
      <Route
        exact
        path="/privacy"
        element={<SwmsPrivacyAndPolicy />}
      />
      <Route
        exact
        path="/terms"
        element={<SwmsTermsAndCondition />}
      />
    </Routes>
  );
};

export const VitalSuperSubRouting = () => {
  return (
    <Routes>
      <Route exact path="/" element={<VitalPage />} />
      <Route
        exact
        path="/privacy"
        element={<VitalPrivacyAndPolicy />}
      />
      <Route
        exact
        path="/terms"
        element={<VitalTermsAndCondition />}
      />
    </Routes>
  );
};