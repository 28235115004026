import React, { useEffect, useRef } from "react";
import "../../style/swmspage.css";
import Bulb from "../../assets/images/swms-bulb.png";
import feature from "../../assets/images/swms-feature.png";
import Apple from "../../assets/images/apple-mobile.png";
import Architecture from "../../assets/images/architecture.png";
import Available from "../../assets/images/available-on.png";
import User from "../../assets/images/user-interface.png";
import quotation from "../../assets/images/quotation.png";
import Footer from "../../components/nav-bar/Footer";
import swmsNew from "../../assets/images/swms-new.png";
import swmsMobile from "../../assets/images/swms-new-mob-view.png";
import { useNavigate } from "react-router-dom";

function SwmsPage() {
  const Navigate = useNavigate();
  const ContactClick = () => {
    Navigate("/contact-us");
  };
  const swmsRef = useRef(null);
  useEffect(() => {
    swmsRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  return (
    <div className="swm-system" ref={swmsRef}>
      <div className="swms-sub">
        <div className="swms-first-img">
          <div className="swms-page-contact">
            <img src={swmsNew} className="swms-new-img" alt="swms-new" />
          </div>
        </div>
        <div className="swms-first-phone-view">
          <div className="swms-text-contact-phone-view">
            <img src={swmsMobile} className="swms-new-img" alt="swms-new" />
          </div>
        </div>
        <div className="swms-second-img">
          <div className="swms-second-contact">
            <div className="swms-second-para">
              <p>
                Smart Water Management System (SWMS) is a cutting-edge solution
                that revolutionizes the way water is managed in residential,
                commercial and industrial settings. SWMS uses advanced IoT
                sensors and data analytics to monitor and analyze water usage,
                detect leaks and wastage and optimize water consumption in
                real-time. With SWMS, you can achieve significant cost savings
                on your water bills, reduce your environmental footprint and
                ensure sustainable water management practices.
              </p>
            </div>
            <div className="swms-second-page">
              <img src={Bulb} className="second-img" alt="swms-Bulb" />
            </div>
          </div>
        </div>
        <div className="swms-third-img">
          <div className="swms-third-contact">
            <div className="swms-third-page">
              <img src={feature} className="third-img" alt="swms-feature" />
            </div>
            <div className="swms-third-para">
              <img src={quotation} className="third-img" alt="swms-feature" />
            </div>
          </div>
        </div>
        <div className="swms-fourth-img">
          <div className="swms-fourth-contact">
            <div className="swms-apple-page">
              <img src={Apple} className="fourth-img" alt="apple-feature" />
            </div>
            <div className="swms-content-contact">
              <div className="swms-whole-text">
                <h1 className="swms-title-download">Download Now</h1>
                <h3 className="swms-title-download">Our New Mobile App</h3>
                <p className="download-para">
                  The finest app to prevent spilling water in homes, factories,
                  schools, universities and other places.
                </p>
                <img
                  src={Available}
                  className="available-img"
                  alt="availabe-feature"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="system-Architecture">
          <div className="swms-Architecture-page">
            <img src={Architecture} className="architech-img" alt="architech" />
          </div>
        </div>
        <div className="user-interface">
          <div className="swms-user-page">
            <img src={User} className="user-img" alt="interface" />
          </div>
        </div>
        <div className="swms-content-liner-color-img">
          <div className="swms-content-liner-color">
            <p className="swms-consult">
              Learn more about optimizing your business process or discuss your
              project by scheduling a free consultation
            </p>
          </div>
          <div className="swms-page-btn-liner">
              <button onClick={() => ContactClick()} className="swms-lets-talk">
                let’s talk
              </button>
          </div>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default SwmsPage;

