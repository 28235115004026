import React, { useEffect, useRef } from "react";
import "../../style/dtdsPage.css";
import Dtds from "../../assets/images/dtds.svg";
import App from "../../assets/images/app.svg";
import Book from "../../assets/images/book.svg";
import Token from "../../assets/images/token.svg";
import Nonmob from "../../assets/images/nonmob.svg";
import Dtdsmobs from "../../assets/images/dtdsmobs.svg";
import Footer from "../../components/nav-bar/Footer";
import { useNavigate } from "react-router-dom";

function DtdsPage() {
  const playStoreLink = 'https://play.google.com/store/apps/details?id=in.tritonlabs.dtdsmobilev1';
  const Navigate = useNavigate();
  const ContactClick = () => {
    Navigate("/contact-us");
  };
  const dtdsRef = useRef(null);
  useEffect(() => {
    dtdsRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  
  function handleClick() {
    window.open(playStoreLink);
  }
  return (
    <div className="dtds-main" ref={dtdsRef}>
      <div className="image-head">
        <img src={Dtds} alt="Let's Book The Token" className="dtds-pic" />
        <div className="clickable-img" onClick={handleClick} />
      </div>
      <div className="dtds-token-app">
        <div className="dtds-patient-app">
          <h1 className="dtds-patient">patient / users app</h1>
          <div className="dtds-token">
            Once a user selects a date, time, hospital, and doctor / official
            during the token booking process, the app will automatically reserve
            a token and generate a unique token number for the user to display.
          </div>
        </div>
        <div className="dtds-app">
          <img src={App} alt="Let's Book The Token" className="dtds-app-app" />
        </div>
      </div>
      <div className="dtds-book-app">
        <div className="dtds-book">
          <img
            src={Book}
            alt="Let's Book The Token"
            className="dtds-book-sys"
          />
        </div>
        <div className="doctor-app">
          <h1 className="doctor">doctor / official app</h1>
          <p className="login">
            The system enables doctors to quickly access and review a patient’s
            / user's history & appointment details, and current token number. It
            also allows doctors / officials to view how many patients / users are
            scheduled for the day, helping them to manage their time
            more efficiently.
          </p>
        </div>
      </div>
      <div className="display-dtds">
        <div className="display-token-app">
          <h1 className="display">token display system</h1>
          <p className="display-token">
            The system displays the most recent token number on the hospital
            television to keep patients updated on their token status, helping
            to reduce overcrowding and congestion in the waiting area.
          </p>
        </div>
        <div className="token-no">
          <img
            src={Token}
            alt="Let's Book The Token"
            className="app-token-no"
          />
        </div>
      </div>
      <div className="dtds-user-app">
        <div className="non-mob">
          <img
            src={Nonmob}
            alt="Let's Book The Token"
            className="non-mob-app"
          />
        </div>
        <div className="dtds-app-application">
          <h1 className="dtds-user">Admin App</h1>
          <p className="dtds-application">
            The apparatus had a temperature sensor placed in the center, and the
            patient was instructed to place their hand near it upon entering to
            measure their body temperature.
          </p>
        </div>
      </div>
      <div>
        <img src={Dtdsmobs} alt="Let's Book The Token" className="dtds-mob" />
      </div>
      <div className="dtds-blur">
        <div className="dtds-content">
          <p className="dtds-consult">
            Learn more about optimizing your business process or discuss your
            project by scheduling a free consultation
          </p>
        </div>
        <div className="dtds-button">
          <button onClick={() => ContactClick()} className="dtds-talk">
            Let's Talk
          </button>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default DtdsPage;
