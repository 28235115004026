import React, { useEffect, useRef } from "react";
import "../../style/careers.css";
import Color from "../../assets/images/color.svg";
import D from "../../assets/images/d.svg";
import Box from "../../assets/images/box.svg";
import tritonoffice from "../../assets/images/tritonoffice.jpg"
import Blue from "../../assets/images/blue.svg";
import Yellow from "../../assets/images/yellow.svg";
import Green from "../../assets/images/green.svg";
import Last from "../../assets/images/last.svg";
import Footer from "../../components/nav-bar/Footer";

function Careers() {
  const careerRef = useRef(null);
  useEffect(() => {
    careerRef.current.scrollIntoView({ behavior: "smooth" }, (0, 400));
  }, []);
  return (
    <>
      <div className="career-main">
        <div className="career-main-topadj" ref={careerRef}></div>
        <div className="career-menu">
          <div className="build-up">
            <h1 className="future">Build Your Future With <span className="front-clr">Triton Tech Labs</span></h1>
          </div>
          <div className="three-pics">
            <img src={Color} alt="Lets Join With As" className="team" />
          </div>
        </div>
        <div className="cult">
          <img src={D} width={"100%"} alt="FEEL HAPPY" />
          <h1 className="culture">Our Work Culture</h1>
        </div>
        <div className="people">
          <img
            src={Box}
            width={"50%"}
            alt="Our People First Organisation"
            className="system"
          />
          <div className="organisation">
            <h1 className="proud">
              We Are Proud To Be a People First Organisation
            </h1>
            <p className="community">
              We are a community of solution
              providers combining human
              ingenuity experience and
              technology innovation to deliver
              sustained outcomes and build
              trust.
            </p>
          </div>
        </div>
        <div>
          <div className="four-icon">
            <div className="oriented">
              <img src={Blue} className="oriented-img" alt="Oriented" />
              <span>People Oriented</span>
            </div>
            <div className="oriented">
              <img src={Yellow} className="oriented-img" alt="Leadship" />
              <span>Leadership</span>
            </div>
            <div className="oriented">
              <img src={Green} className="oriented-img" alt="Innovation" />
              <span>Innovation</span>
            </div>
            <div className="oriented">
              <img src={Last} className="oriented-img" alt="Outcome" />
              <span>Outcome Oriented</span>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "30px" }}>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Careers;
