import React from "react";
import VitalHeadNav from "../../components/nav-bar/VitalHeadNav";
import { VitalSuperSubRouting } from "../../routes/Routing";

function VitalMain() {
  return (
    <div className="main">
      <div className="sub-main">
        <div className="head-component">
          <VitalHeadNav />
        </div>
        <div className="body-component">
          <VitalSuperSubRouting />
        </div>
      </div>
    </div>
  );
}

export default VitalMain;