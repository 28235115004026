import React, { useEffect, useRef } from "react";
import "../../style/privacyandpolicy..css";

function DtdsPrivacyAndPolicy() {
  const dtdsPrivacyAndPolicyRef = useRef(null);
  useEffect(() => {
    dtdsPrivacyAndPolicyRef.current.scrollIntoView({ behavior: "smooth" }, (0, 400));
  }, []);
  return (<div className="privacy-policy-main-page">
    <div className="sub-privacy-policy-page">
      <div className="contant-privacy-policy-page">
      <div className="privacy-policy-topadj" ref={dtdsPrivacyAndPolicyRef}></div>
        <div className="privacy-policy-heading-text">
          At Triton Tech Labs, we take the privacy and security of your personal information very seriously. This privacy policy
          outlines the ways in which we collect, use and protect your data when you use our DIGITAL TOKEN DISPLAY SYSTEM.
        </div>
        <div className="all-privacy-policy-para-main">
          <div className="privacy-policy-heading-para-main">
            <div className="privacy-policy-heading">
              <h1>Information Collection</h1>
            </div>
            <div className="privacy-policy-para-page">
              We collect certain information about you when you use our
              system, including:
            </div>
            <div className="privacy-policy-para-list-page">
              <ul className="list-privacy-policy-para">
                <li>
                  Personal information such as your name, email address and
                  contact information
                </li>
                <li>
                  Usage information such as your device information, browser
                  type and IP address
                </li>
                {/* <li>
                  Water usage data such as your water consumption and usage
                  patterns
                </li> */}
              </ul>
            </div>
          </div>
          <div className="privacy-policy-heading-para-main">
            <div className="privacy-policy-heading">
              <h1>Information Use</h1>
            </div>
            <div className="privacy-policy-para-page">
              We use the information we collect for the following purposes:
            </div>
            <div className="privacy-policy-para-list-page">
              <ul className="list-privacy-policy-para">
                <li>
                  To provide you with access to our DIGITAL TOKEN DISPLAY SYSTEM and its features
                </li>
                <li>To analyze and improve our system and services</li>
                <li>
                  To communicate with you about our system and services,
                  including updates and news
                </li>
                <li>To comply with legal obligations</li>
              </ul>
            </div>
          </div>
          <div className="privacy-policy-heading-para-main">
            <div className="privacy-policy-heading">
              <h1>Information Sharing</h1>
            </div>
            <div className="privacy-policy-para-page">
              We may share your information with third-party service providers
              who assist us with the operation of our system and services. We
              will only share your information as necessary to fulfill the
              purposes outlined in this privacy policy.
            </div>
          </div>
          <div className="privacy-policy-heading-para-main">
            <div className="privacy-policy-heading">
              <h1>Data Security</h1>
            </div>
            <div className="privacy-policy-para-page">
              We take appropriate measures to protect your data from
              unauthorized access, use and disclosure. We use
              industry-standard encryption technologies and follow best
              practices for data security.
            </div>
          </div>
          <div className="privacy-policy-heading-para-main">
            <div className="privacy-policy-heading">
              <h1>Data Retention</h1>
            </div>
            <div className="privacy-policy-para-page">
              We will retain your data for as long as necessary to fulfill the
              purposes outlined in this privacy policy, unless a longer
              retention period is required or permitted by law.
            </div>
          </div>
          <div className="privacy-policy-heading-para-main">
            <div className="privacy-policy-heading">
              <h1>Your Rights</h1>
            </div>
            <div className="privacy-policy-para-page">
              You have the right to access, correct and delete your personal
              information. You may also have additional rights depending on
              your location and applicable laws.
            </div>
          </div>
          <div className="privacy-policy-heading-para-main">
            <div className="privacy-policy-heading">
              <h1>Contact Us</h1>
            </div>
            <div className="privacy-policy-para-page">
              If you have any questions or concerns about our privacy policy
              or practices, please contact us at +91 9944393255 or &nbsp;
              <a className="privacy-policy-para-page-mail" href="/contact-us">info@tritonlabs.in</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}

export default DtdsPrivacyAndPolicy;
