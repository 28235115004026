import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { VitalMenuItem } from "./menu";
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, MenuItem, Menu } from '@mui/material';
import VsLog from "../../assets/images/vs-log.png";
function VitalHeadNav() {
  const location = useLocation();
  const path = location.pathname.split("/");
  const [anchorEl, setAnchorEl] = useState(undefined);
  const [navTitle, setNavTitle] = useState(path[path.length - 1]);
  const [open, setOpen] = useState(false);
  const handleClick = (event) => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickNav = (title) => {
    setNavTitle(title);
    setOpen(false);
  };
  useEffect(() => {
    if (path[path.length - 1] === "vital-sense") {
      setNavTitle("");
    } else {
      setNavTitle(path[path.length - 1]);
    }
  }, [path]);

  return (
    <div className="header-main">
      <div className="triton-logo">
        <Link to={"/home"}>
          <img src={VsLog} width={"40px"} alt="tritonlogo" />
        </Link>
      </div>
      <div className="top-navbar">
        <nav className="nav-menu">
          <div className="nav-bar">
            <div className="nav-menu-item">
              {VitalMenuItem?.map((item, index) => {
                return (
                  <div key={index} className={item.cName}>
                    <NavLink to={item.path} className="link">
                      <div className={`title-name active-nav ${navTitle === item.path ? 'clicked-nav' : ''}`} onClick={() => handleClickNav(item.path)} >{item.title}</div>
                    </NavLink>
                  </div>
                );
              })}
            </div>
            <div className="dtds-navbar-toggle">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}>
                <MenuIcon fontSize="medium" />
              </IconButton>
              <Menu
                onClose={handleClose}
                id="account-menu"
                open={open}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 8.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 2,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
              >
                {VitalMenuItem?.map((item, index) => {
                  return (
                    <MenuItem key={index}>
                      <NavLink to={item.path} className="link" onClick={handleClick}>
                        {item.title}
                      </NavLink>
                    </MenuItem>
                  )
                })}
              </Menu>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default VitalHeadNav;
