import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../style/contact.css";
import Mail from "../../assets/images/mail.svg";
import Phone from "../../assets/images/phone.svg";
import Address from "../../assets/images/address.svg";
import Footer from "../../components/nav-bar/Footer";
import ReactGA from 'react-ga';

function ContactUs() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const form = useRef();

  const onSubmit = (data) => {
    ReactGA.event({
      category: 'Contact Us',
      action: 'Mail to Website'
    });
    emailjs
      .sendForm(
        "service_oe4jorq",
        "template_v3q5g2w",
        form.current,
        "b6efl5CvMYx_vsE2n"
      )
      .then(
        (result) => {
          toast.success("Message Sent", {
            position: toast.POSITION.TOP_CENTER,
          });
        },
        (error) => {
          console.log(error.text);
        }
      );
    reset();
  };

  const contactRef = useRef(null);
  useEffect(() => {
    contactRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  return (
    <div className="contact-us-container" ref={contactRef}>
      <div className="contact-head">
        <div className="contact-container">
          <div className="contact-sub-container">
            <div className="contact-title">
              <h1 className="contact-title-head">Get In Touch...</h1>
            </div>
            <div className="contact-card">
              <ToastContainer autoClose={2000} />
              <div className="form">
                <form ref={form} onSubmit={handleSubmit(onSubmit)}>
                  <div className="contact-name-field">
                    <label className="form-label">Name</label>
                    <input
                      type="text"
                      {...register("user_name", {
                        required: "Please enter your Name",
                      })}
                      className="form-control"
                      placeholder="Enter Your Name"
                      name="user_name"
                    />
                    <span className="error-text">
                      {errors.user_name?.message}
                    </span>
                  </div>
                  <div className="contact-email-field">
                    <label className="form-label">Email</label>
                    <input
                      type="email"
                      {...register("user_email", {
                        required: "Please enter your Email-id",
                      })}
                      className="form-control"
                      placeholder="Enter Your email"
                      name="user_email"
                    />
                    <span className="error-text">
                      {errors.user_email?.message}
                    </span>
                  </div>
                  <div className="contact-message-field">
                    <label className="form-label">Message</label>
                    <textarea
                      className="form-control form-message resize-none"
                      {...register("message", {
                        required: "Please enter your Message",
                      })}
                      name="message"
                      type="text"
                      placeholder="Enter Your Meesage"
                    />
                    <span className="error-text">
                      {errors.message?.message}
                    </span>
                  </div>
                  <div>
                    <button type="submit" className="contact-btn">
                      Enquiry
                    </button>
                  </div>
                </form>
              </div>
              <div className="map">
                <iframe
                  title="map WebSite"
                  className="map-iframe"
                  style={{ borderRadius: "15px" }}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3939.7797436283768!2d77.34598027467301!3d9.083819290979743!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b0685fe19fac267%3A0x7cae5d4effd10863!2sTriton%20Tech%20Labs%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1682497003056!5m2!1sen!2sin"
                  loading="lazy"
                ></iframe>
              </div>
            </div>
            <div className="contact">
              <div className="contact-mail">
                <img className="contact-img" src={Mail} alt="Mail logo" />
                <h4 className="conatct-head" style={{ marginBottom: "10px" }}>
                  Mail
                </h4>
                <a className="contact-mail-in" href="mailto:info@tritonlabs.in">
                  info@tritonlabs.in
                </a>
              </div>
              <div className="contact-phone">
                <img className="contact-img" src={Phone} alt="Mail logo" />
                <h4 className="conatct-head">Phone</h4>
                <p className="contact-para">+91 9944393255</p>
              </div>
              <div className="contact-address">
                <img className="contact-img" src={Address} alt="Mail logo" />
                <h4 className="conatct-head">Address</h4>
                <div className="address-new-div">
                  <p className="contact-para">
                    219, First floor, Main road,  Kadayanalur,<br></br>
                    Tenkasi,India-627751 <br></br>

                  </p>
                  <p className="contact-para">
                    191, 3rd Floor, Hamid Building Whites Road,<br></br>
                    Anna Salai, Chennai <br></br>
                    India-600006
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "30px", height: "30%" }}>
        <Footer />
      </div>
    </div>
  );
}

export default ContactUs;
