import React, { useEffect, useRef } from "react";
import "../../style/ourservice.css";
import Business from "../../assets/images/business.svg";
import Content from "../../assets/images/content.svg";
import Footer from "../../components/nav-bar/Footer";
import Globe from "../../assets/images/globe.png"
import { useNavigate } from "react-router-dom";
import ReactGA from 'react-ga';
import nippon from "../../assets/images/Nippon.png"
import ideassion from "../../assets/images/ideassion.png"
import talent from "../../assets/images/talentsearch.png"
import vue from "../../assets/images/vue.png"
import indiacharger from "../../assets/images/indiacharger.png"
import blueripple from "../../assets/images/blueripples.jpeg"
import pdfarabic from "../../assets/images/pdfarabic.png"
import primesquad from "../../assets/images/primesquad.png"

function OurService() {
  const Navigate = useNavigate();
  const ContactClick = () => {
    ReactGA.event({
      category: 'Contact User',
      action: 'User enquiry about site'
    });
    Navigate("/contact-us");
  };
  const servicesRef = useRef(null);
  useEffect(() => {
    servicesRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <div className="container" ref={servicesRef}>
      <div>
        <img src={Business} className="service" alt="Draw your Dreams" />
      </div>
      <div className="service-head">
        <p className="service-para">
          At Triton Tech Labs, we offer a range of digital solutions designed to
          help businesses of all sizes achieve their goals. Our services include
          (IoT Solutions, Mobile App Development, Custom Software Development,
          Web Development, Cloud Solutions, Automation With AI and Big Data
          Analytics), each delivered with the same commitment to quality and
          customer satisfaction. Take a look at some of our recent projects to
          see how we can help you take your business to the next level.
        </p>
      </div>
      <div>
        <img src={Globe} className="globeImg" alt="GLOBE" />
      </div>
      <div className="content">
        <img src={Content} className="contentImg" alt="Services" />
      </div>
      <div className="clients-main-cont">
        <h1>Our Clients</h1>
        <span>Meet our Happy Clients</span>
        <div className="clients-sub-div">
          <div className="client-div">
            <img src={nippon} className="" alt="nippon" />
          </div>
          <div className="client-div">
            <img src={ideassion} className="" alt="ideassion" />
          </div>
          <div className="client-div">
            <img src={talent} className="" alt="talent" />
          </div>
          <div className="client-div">
            <img src={vue} className="" alt="vue" />
          </div>
          <div className="client-div">
            <img src={indiacharger} className="" alt="indiacharger" />
          </div>
          <div className="client-div">
            <img src={blueripple} className="" alt="blueripple" />
          </div>
          <div className="client-div">
            <img src={pdfarabic} className="" alt="pdfarabic" />
          </div>
          <div className="client-div">
            <img src={primesquad} className="" alt="primesquad" />
          </div>
        </div>

      </div>
      <div className="serve-blur">
        <div className="service-content">
          <p className="consult">
            Learn more about optimizing your business process or discuss your
            project by scheduling a free consultation
          </p>
        </div>
        <div className="service-button">
          <button onClick={() => ContactClick()} className="talk">
            Let's Talk
          </button>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default OurService;
