import React from "react";
import "../style/main.css";
import HeadNav from "../components/nav-bar/HeadNav";
import { SubRouting } from "../routes/Routing";
function Main() {
  return (
    <div className="main">
      <div className="head-component">
        <HeadNav />
      </div>
      <div className="body-component">
        <SubRouting />
      </div>
    </div>
  );
}
export default Main;
